// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;
export const CUSTOMER_SITE_API = process.env.REACT_APP_CUSTOMER_SITE_API;

export const ABLY_API = {
  apiKey: process.env.REACT_APP_ABLY_API_KEY,
  channel: process.env.REACT_APP_ABLY_CHANNEL,
};

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
  userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.schedule.root;

// Reservations
// ----------------------------------------------------------------------
export const RESERVATION_STATUS_OPTIONS = [
  { value: 'CANCELED', label: 'Canceled' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'READY', label: 'Ready' },
  { value: 'COMPLETED', label: 'Completed' },
];

export const isActiveReservationStatus = (status) => {
  const activeStatus = new Set(['ACTIVE', 'READY', 'COMPLETED']);
  return activeStatus.has(status);
};

export const reservationStatusValueToLabel = (status) => {
  const valueToLabelMap = RESERVATION_STATUS_OPTIONS.reduce((acc, obj) => {
    acc.set(obj.value, obj.label);
    return acc;
  }, new Map());
  if (!status || !valueToLabelMap.has(status)) return 'Unknown';
  return valueToLabelMap.get(status);
};

export const SIZE_OPTIONS = [
  '4"',
  '4" Extra Tall',
  '5"',
  '5" Extra Tall',
  '6"',
  '6" Extra Tall',
  '8"',
  '8" Extra Tall',
  '8"+6"',
  'Other (see note)',
];

export const FLAVOR_OPTIONS = [
  'Original',
  'Chocolate',
  'Strawberry',
  'Matcha',
  'Oreo',
  'Blueberry',
  'Coffee',
  'Black tea',
  'Other (see note)',
];

export const BASQUE_FILLING = 'Basque Cheesecake';
export const FILLING_OPTIONS = [
  'Strawberry',
  'Blueberry',
  'Grapes',
  'No fruit filling',
  'Other (see note)',
];

export const WRITING_LOCATION_OPTIONS = [
  { label: 'Cake Board', value: 'CAKE_BOARD' },
  { label: 'On the Top', value: 'ON_THE_TOP' },
  { label: 'Topper Card', value: 'TOPPER_CARD' },
];

export const OTHER_MODIFIER_OPTIONS = [{ label: 'Date Being Marked', value: 'markDate' }];

export const CUSTOM_RESERVATION_COLOR_OPTIONS = [
  '#7F7F7F', // grey
  '#D7A8A7', // pink
  '#F4D576', // yellow
  '#72BDBF', // blue
  '#FF3232', // red
  '#8E7CC3', // purple
  '#FFA500', // orange
];

export const DEFAULT_CUSTOM_RESERVATION_COLOR = CUSTOM_RESERVATION_COLOR_OPTIONS[0];
export const REGULAR_RESERVATION_COLOR = '#18A558'; // green

export const RESERVATION_COLOR_OPTIONS = [
  '#7F7F7F', // grey
  '#D7A8A7', // pink
  '#F4D576', // yellow
  '#72BDBF', // blue
  '#FF3232', // red
  '#8E7CC3', // purple
  '#18A558', // green
  '#FFA500', // orange
];

export const DEFAULT_RESERVATION_COLOR = RESERVATION_COLOR_OPTIONS[0];
